import React from 'react'
import './Hero.css'
import Header from '../Header/Header.jsx'

import hero_im from '../../assets/hero_im.png';
import hero_image_back from"../../assets/hero_image_back.png";
import Heart from "../../assets/heart.png";
import Calories from "../../assets/calories.png";
import {motion} from 'framer-motion';

const Hero = () => {
  const transition ={type:'spring', duration:3}
  const mobile= window.innerWidth<=768 ? true:false;
  return (
    <div className="hero">
      <div className="blur blur-h"></div>
      <div className="left-h">
        <Header/>
     
     {/*the best ad*/}

        <div className="the-best-ad">
            <motion.div
            initial={{left:mobile? "168px":'238px'} }
            whileInView= {{left:'8px'}}
            transition={{...transition,type:'tween'}}
            >
            </motion.div>
            <span>The best fitness gym in our city</span>
   </div>

        {/*Hero Heading*/}
        <div className="hero-text">
            <div>
                <span className='stroke-text'>Shape </span>
                <span>Your</span>
            </div>
            <div>
                <span>Ideal body</span>
            </div>
            <div>
                <span>
                    IN HERE WE WILL HELP YOU TO SHAPE AND BUILD YOUR
                    IDEAL BODY AND LIVE UP YOUR LIFE TO FULLEST
                </span>
            </div>
        </div>

        {/*figures*/}

        <div className="figures">
            <div>
            <span>+140</span>
            <span>Expert couches</span>
            </div>

            <div>
            <span>+970 </span>
            <span>members joined</span>
            </div>

            <div>
            <span>+50</span>
            <span>fitness programs</span>
            </div>
       
        </div>

        {/*buttons*/}
        <div className="hero-btns">
            <button className='btn'>Get started</button>
            <button className='btn' >Learn more</button>
        </div>
    
    </div>

     
      <div className="right-h">
        <button className='btn'>Join now</button>

        <motion.div
        initial={{right:'-1rem'}}
        whileInView={{right:'4rem'}}
        transition={transition}

        
        className='heart-rate'>
            <img src={Heart} alt=''/>
            <span>Heart Rate </span>
            <span> 116 bpm</span>
        </motion.div>
        
        {/*imges*/}

        <img src={hero_im}alt='' className='hero-image'></img>
        <motion.img
        initial={{right:'11rem'}}
        whileInView={{right: '20rem'}}
        transition={transition}
        src={hero_image_back} alt='' className='hero-image-back'></motion.img>
          
          {/*calories*/}

          <motion.div 
          initial={{right:'37rem'}}
          whileInView={{right: '28rem'}}
          transition={transition}

          className='calories'>
            <img src={Calories} alt=''/>
            <span>Calories Burned</span>
            <span>220 kcal</span>
          </motion.div>
          </div> 
          
    </div>
  )
}

export default Hero
