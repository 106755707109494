import React from 'react'
import './Header.css';
import Logo from '../../assets/logo.png'
import Logo1 from '../../assets/logo 1.png'
import { Link} from 'react-scroll';
import Bars from '../../assets/bars.png'
import { useState } from 'react';
const Header = () => {
  const mobile= window.innerWidth<=786 ? true:false
  const[menuOpened, setMenuOpened]= useState(false)
  return (
    <div className="Header">
        <img src={Logo1} alt='' className='logo'/>
        {menuOpened=== false && mobile=== true? (
          <div style={{backgroundColor:'var(--appColor)',
                          padding:'0.5rem', 
                          borderRadius:'5px',
                          marginLeft:'16.8rem',
                          marginTop:'-4rem'}}
            onClick={() => setMenuOpened(true)}
          >
            <img src={Bars} alt="" style={ {width:'1.5rem', height:'1.5rem'}} />
            </div>
            ):( 
    <ul className='header-menu'> 
        <li>
          <Link
           onClick={()=>setMenuOpened(false)}
           activeClass='active'
           to='header'
           span={true}
           smooth={true}
          >Home</Link></li>
        <li onClick={()=>setMenuOpened(false)}><Link
         onClick={()=>setMenuOpened(false)}
         to='Program'
         span={true}
         smooth={true}
        >Program</Link></li>
        <li onClick={()=>setMenuOpened(false)}><Link
         onClick={()=>setMenuOpened(false)}
         to='reasons'
         span={true}
         smooth={true}
        >Why us?</Link></li>
        <li onClick={()=>setMenuOpened(false)}><Link
         onClick={()=>setMenuOpened(false)}
         to='plans'
         span={true}
         smooth={true}
        >Plans</Link></li>
        <li onClick={()=>setMenuOpened(false)}><Link
        onClick={()=>setMenuOpened(false)}
        to='Testimonials'
        span={true}
        smooth={true}
        >Testimonials </Link></li>
    </ul>
    )}
   
    </div>
  )
}

export default Header
