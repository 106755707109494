import React from 'react'
import './Program.css'
import {programsData} from '../../data/programsData'
import RightArrow from '../../assets/rightArrow.png'
const Program = () => {
  return (
    <div className='Program' id='programs'>
     <div className="program-header">
      <span className='stroke-text'>Explore our </span>
      <span>Programs </span>
      <span className='stroke-text'>to shape you</span>
      </div> 

      <div className="progarm-category">
            {programsData.map((Program)=>(
                <div className="category">
                    {Program.image}
                    <span>{Program.heading}</span>
                    <span>{Program.details}</span>
                    <div className="join-now"><span>join now</span><img src={RightArrow}></img></div>
                </div>
            ))}

      </div>
    </div>
  )
}

export default Program
